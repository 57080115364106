import React from 'react';
import {Link} from 'gatsby'

const WhatWeDo = () => {
    return (
        <section className="what-we-do-area bg-fafafb pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>¿ Cómo es trabajar con YFTEL ?</h2>
                    <p></p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-what-we-do-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>
                            <h3>
                                    Analizar
                            </h3>
                            <p>Analizamos los proceso de alta, baja, suspensión y reconexión de servicios.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-what-we-do-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>
                            <h3>
                                    Integrar
                            </h3>
                            <p>Integramos la plataforma con la red y distintos componentes del sistemas de negocio</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="single-what-we-do-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>
                            <h3>
                                    Automatizar
                            </h3>
                            <p>Automatizamos los pagos de servicios y la creación de planes de internet para que tu ISP sea más competitivo</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhatWeDo;
