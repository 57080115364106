import React from 'react';
import {Link} from 'gatsby'

import ServiceIcon7 from '../../assets/images/services/service-icon7.png'
import ServiceIcon8 from '../../assets/images/services/service-icon8.png'
import ServiceIcon9 from '../../assets/images/services/service-icon9.png'
import ServiceIcon10 from '../../assets/images/services/service-icon10.png'
import ServiceIcon11 from '../../assets/images/services/service-icon11.png'
import ServiceIcon12 from '../../assets/images/services/service-icon12.png'
import ServiceShape4 from '../../assets/images/services/service-shape4.png'

const Services = () => {
    return (
        <section className="services-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <h2>Caracteristicas de Nuestra Plataforma</h2>
                    <p></p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={ServiceIcon9} alt="Service Icon" />
                            </div>
                            <h3>
                                   Escalable 
                            </h3>
                            <p>Escalable hasta +1M de suscriptores.</p>

                            <div className="shape">
                                <img src={ServiceShape4} alt="Escalable" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={ServiceIcon7} alt="Service Icon" />
                            </div>
                            <h3>
                                    SAAS
                            </h3>
                            <p>Software como servicio en data centers de clase mundial.</p>
                            
                            <div className="shape">
                                <img src={ServiceShape4} alt="SAAS" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={ServiceIcon12} alt="Service Icon" />
                            </div>
                            <h3>
                                    Planes Promocionales
                            </h3>
                            <p>Crea planes a la medida con fines promocionales en nuestra plataforma en menos de 5 cliicks.</p>
                            
                            <div className="shape">
                                <img src={ServiceShape4} alt="Planes" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default Services;
