import React from 'react'
import user1 from '../../assets/images/user1.jpg'
import user2 from '../../assets/images/user2.jpg'
import user3 from '../../assets/images/user3.jpg'
import shape9 from '../../assets/images/shape/vector-shape9.png'
import shape10 from '../../assets/images/shape/vector-shape10.png'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <section className="testimonials-area bg-23173a pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>Testimonio</h2>
                </div>

                    <div className="single-testimonials-box">
                        <img src={user3} className="shadow-sm" alt="testimonials" />
                        <p>YFTEL facilitó la integración de su plataforma AAA con la nuestra,permitiendonos proveer un buen servicio a nuestros clientes de red FTTH; de esta manera automatizamos nuestra operación , redujimos la morosidad y frenamos el abuso del servicio..</p>

                        <div className="client-info">
                            <div className="title">
                                <h3>Gerente TI</h3>
                                <span>ISP PERU</span>
                            </div>
                        </div>
                    </div>

            </div>

            <div className="vector-shape9">
                <img src={shape9} alt="testimonials" />
            </div>
            <div className="vector-shape10">
                <img src={shape10} alt="testimonials" />
            </div>
        </section>
    )
}

export default Testimonials
