import React from 'react';
import Navbar from "../components/App/YftelNavbar2"
import Footer from "../components/App/YftelFooter"
import Layout from "../components/App/YftelLayout"
import MainBanner from '../components/YftelPortalWifi/MainBanner'
import FeaturedServices from '../components/YftelPortalWifi/FeaturedServices'
import WhyChooseUs from '../components/YftelPortalWifi/WhyChooseUs'
import WhatWeDo from '../components/YftelPortalWifi/WhatWeDo'
import Funfacts from '../components/YftelPortalWifi/Funfacts'
import Services from '../components/YftelPortalWifi/Services'
import Testimonials from '../components/YftelPortalWifi/Testimonials'
import WeServe from '../components/YftelPortalWifi/WeServe'
import TeamMembers from '../components/YftelPortalWifi/TeamMembers'
import CaseStudy from '../components/YftelPortalWifi/CaseStudy'
import BlogPost from '../components/YftelPortalWifi/BlogPost'
import StartProject from '../components/Common/YftelStartProject';

const Home = () => {
    return (
        <Layout>
            <Navbar />
            <MainBanner />
            <Funfacts />
            <Services />
            <StartProject />
            <WhyChooseUs />
            <Testimonials />
            <FeaturedServices />
            <WhatWeDo />
            <TeamMembers />
            <CaseStudy />
        {/* <BlogPost /> */}
            <Footer />
        </Layout>
    );
}

export default Home;
