import React from 'react';
import {Link} from 'gatsby'

const FeaturedServices = () => {
    return (
        <section className="featured-services-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>¿ Te Cuesta Captar Clientes ?</h2>
                    <p></p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-tick"></i>
                            </div>
                            <h3>
                                   Intensa Competencia con otros Operadores para captar clientes 
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-tick"></i>
                            </div>
                            <h3>
                                    Evita una guerra de precios que solo benefician a los clientes
                            </h3>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-tick"></i>
                            </div>
                            <h3>
                                   ¿ Necesitas mayor cantidad de planes para aumentar tu competitividad en el mercado ?
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedServices;
