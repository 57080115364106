import React from 'react';
import {Link} from 'gatsby'

import MapShape2 from '../../assets/images/shape/map-shape2.png'
import Partner1 from '../../assets/images/partner/partner1.png'
import Partner2 from '../../assets/images/partner/partner2.png'
import Partner3 from '../../assets/images/partner/partner3.png'

const WhyChooseUs = () => {
    return (
        <section className="why-choose-us-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="why-choose-us-content">
                            <h2>Yftel Es Tu Socio Estrategico</h2>
                            <p>Entendemos la importancia de seleccionar una plataforma AAA que tenga la flexibilidad de crear planes de internet a la medida. 
                            </p>
                            <p>La plataforma  AAA de Yftel permite a los ISPs con red FFTH crear planes a la medida, controlar el abuso del servicio y reducir la morosidad, integrando la recarga y recaudo de servicios con los procesos de alta, baja, suspensión y reconexión de servicios.</p>
                            
                            <Link to="https://calendly.com/yftel-sdr/60min" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Pide tu Cita <span></span>
                            </Link>

                            <div className="map-shape2">
                                <img src={MapShape2} alt="MapShape" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-brand-partners-list">
                            <h2>Clientes que confían en Yftel</h2>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-sm-6 col-md-4">
                                    <img src={Partner1} alt="Internexa" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-4">
                                    <img src={Partner2} alt="Bantel" />
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-4">
                                    <img src={Partner3} alt="Iway" />
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs;
